import { DataLike } from "../DatagridAsync";
import { Col } from "./Col";

export type ColSingleSelect<T extends DataLike = DataLike> = {
  colType: "singleSelect";
  field: "singleSelect";
  headerName: string;
  validate?: (row: any, input: any) => string;
  pin?: "left" | "right";
  editable?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  hide?: boolean;
  width?: number;
  tz?: string;
  resolve?: (data: T) => string;
};

export const isColSingleSelect = (col: Col): col is ColSingleSelect => {
  return col.colType === "singleSelect";
};
