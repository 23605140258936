import { ColSingleSelect } from "../Col/ColSingleSelect";
import { CustomGridRenderCellParams } from "../types/CustomGridRenderCellParams";
import { CellString } from "../Cell/CellString";

export const ColSingleSelectDef = (colProp: {
  col: ColSingleSelect;
  externalRowStringState: (rowId: number | string) => {
    [key: string]: string;
  };
}) => {
  return {
    field: colProp.col.field,
    headerName: colProp.col.headerName,
    filterable: colProp.col.filterable,
    sortable: colProp.col.sortable,
    renderCell: (params: CustomGridRenderCellParams<string>) => (
      <CellString valueFormatter={(val: string) => val} params={params} />
    ),
    type: "singleSelect" as const,
    editable: colProp.col.editable,
    width: colProp.col.width,
  };
};
