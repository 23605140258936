import {
  GridCellParams,
  GridColType,
  GridFilterItem,
  GridFilterOperator,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import clsx from "clsx";
import { CustomGridRenderCellParams } from "../types/CustomGridRenderCellParams";
import { CustomGridRenderEditCellParams } from "../types/CustomGridRenderEditCellParams";
import { CellBoolean } from "../Cell/CellBoolean";
import { ColBoolean } from "../Col/ColBoolean";
import { EditCellBoolean } from "../EditCell/EditCellBoolean";

type ColBooleanDefResult = {
  field: string;
  headerName: string;
  type: GridColType;
  filterable?: boolean;
  editable?: boolean;
  sortable?: boolean;
  width?: number;
  valueFormatter?: (value: GridValueFormatterParams<boolean>) => string;
  renderCell: (params: CustomGridRenderCellParams<boolean>) => JSX.Element;
  renderEditCell: (
    params: CustomGridRenderEditCellParams<boolean>,
  ) => JSX.Element;
  cellClassName: (params: GridCellParams) => string;
  filterOperators: GridFilterOperator[];
};

export const ColBooleanDef = (colProp: {
  col: ColBoolean;
  externalRowStringState: (rowId: number | string) => {
    [key: string]: string;
  };
  updateExternalRowBooleanState: (
    rowId: number | string,
  ) => (key: string) => (value: boolean) => void;
}): ColBooleanDefResult => {
  const valueFormatter = colProp.col.value
    ? (val: boolean | undefined) => {
        const trueValue = colProp.col.value ? colProp.col.value[0] : "あり";
        const falseValue = colProp.col.value ? colProp.col.value[1] : "なし";
        return val === true ? trueValue : val === false ? falseValue : "";
      }
    : (val: boolean | undefined) => {
        return val === true ? "あり" : val === false ? "なし" : "";
      };
  const isTrueOperator: GridFilterOperator = {
    label: valueFormatter(true),
    value: "isTrue",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }
      return (params: GridCellParams): boolean => {
        return params.value === true;
      };
    },
  };
  const isFalseOperator: GridFilterOperator = {
    label: valueFormatter(false),
    value: "isFalse",
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!filterItem.field || !filterItem.operator) {
        return null;
      }
      return (params: GridCellParams): boolean => {
        return params.value === false;
      };
    },
  };
  return {
    field: colProp.col.field,
    headerName: colProp.col.headerName,
    type: "boolean",
    filterable: colProp.col.filterable,
    editable: colProp.col.editable,
    sortable: colProp.col.sortable,
    width: colProp.col.width,
    valueFormatter: colProp.col.valueFormatter,
    renderCell: (params: CustomGridRenderCellParams<boolean>) => (
      <CellBoolean params={params} valueFormatter={valueFormatter} />
    ),
    renderEditCell: (params: CustomGridRenderEditCellParams<boolean>) => (
      <EditCellBoolean
        params={params}
        width={colProp.col.width}
        updateExternalBooleanState={colProp.updateExternalRowBooleanState(
          params.id,
        )}
        valueFormatter={valueFormatter}
        externalStringState={colProp.externalRowStringState(params.id)}
      />
    ),
    cellClassName: () => {
      return colProp.col.editable ? clsx("editable") : clsx("disabled");
    },
    filterOperators: [isTrueOperator, isFalseOperator],
  };
};
